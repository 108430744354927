
import * as yup from 'yup';

const labelText = (prm) => {
    return prm.label ? `${prm.label}は` : '';
};
const jpLocale = {
    mixed: {
        default: (prm) => `${labelText(prm)}無効です`,
        required: (prm) => `${labelText(prm)}必須の入力項目です`,
        oneOf: (prm) => `${labelText(prm)}次の値のいずれかでなければなりません:${prm.values}`,
        notOneOf: (prm) => `${labelText(prm)}次の値のいずれかであってはなりません:${prm.values}`,
        notType: `形式が違います`,
        defined: ``,
    },
    string: {
        length: (prm) => `${labelText(prm)}${prm.length}文字でなければなりません`,
        min: (prm) => `${labelText(prm)}少なくとも${prm.min}文字でなければなりません`,
        max: (prm) => `${labelText(prm)}最大${prm.max}文字でなければなりません`,
        matches: (prm) => `${labelText(prm)}次の形式と一致する必要があります: "${prm.regex}"`,
        email: (prm) => `${labelText(prm)}メールアドレス形式である必要があります`,
        url: (prm) => `${labelText(prm)}有効なURLでなければなりません`,
        uuid: (prm) => `${labelText(prm)}有効なUUID形式でなければなりません`,
        trim: (prm) => `${labelText(prm)}前後にスペースを入れてはいけません`,
        lowercase: (prm) => `${labelText(prm)}小文字でなければなりません`,
        uppercase: (prm) => `${labelText(prm)}大文字でなければなりません`,
    },
    number: {
        min: (prm) => `${labelText(prm)}${prm.min}以上である必要があります`,
        max: (prm) => `${labelText(prm)}${prm.max}以下でなければなりません`,
        lessThan: (prm) => `${labelText(prm)}${prm.less}より小さくなければなりません`,
        moreThan: (prm) => `${labelText(prm)}${prm.more}より大きくなければなりません`,
        positive: (prm) => `${labelText(prm)}正の数でなければなりません`,
        negative: (prm) => `${labelText(prm)}負の数でなければなりません`,
        integer: (prm) => `${labelText(prm)}整数でなければなりません`,
    },
    date: {
        min: (prm) => `${labelText(prm)}${prm.min}より後でなければなりません`,
        max: (prm) => `${labelText(prm)}${prm.max}より前でなければなりません`,
    },
    boolean: {
        isValue: (prm) => `${labelText(prm)}値が必要です`,
    },
    object: {
        noUnknown: (prm) => `${labelText(prm)}オブジェクトで指定されていないキーを含めることはできません`,
    },
    array: {
        length: (prm) => `${labelText(prm)}${prm.length}個が必要です`,
        min: (prm) => `${labelText(prm)}${prm.min}個以上の項目が必要です`,
        max: (prm) => `${labelText(prm)}${prm.max}個以下の項目が必要です`,
    },
}

yup.setLocale(jpLocale)

export default yup


/* FormObject */

class FormObject {
    constructor(form) {
        this._defaults = {}
        this._schema = {}
        Object.keys(form).forEach((k) => {
            this._schema[k] = form[k]["type"]
            if ("default" in form[k]) {
                this._defaults[k] = form[k]["default"]
            } else {
                this._defaults[k] = ""
            }
        })
    }
    get defaults() {
        return {...this._defaults}
    }
    get schema() {
        return this._schema
    }
    keys() {
        return Object.keys(this.schema)
    }
}

/* AdminUser */

export const AdminUser = new FormObject({
    status: {
        default: "", type: yup.string().oneOf(["active", "locked"]).required(),
    },
    fullname: {
        default: "", type: yup.string().min(1).max(30).required(),
    },
    email: {
        default: "", type: yup.string().email().required(),
    },
    password: {
        default: "", type: yup.string().min(6).max(32).matches(/^[a-zA-Z0-9_+-=?!@#$(){}|^]{6,32}$/, "利用できない文字が使用されています").required(),
    },
    passwordNotRequired: {
        default: "", type: yup.string(),
    },
    retypePassword: {
        default: "", type: yup.string().oneOf([yup.ref("password"), null], "パスワードが不一致です"),
    },
    remember: {
        default: "", type: yup.string(),
    },
})

/* Shop */

export const Shop = new FormObject({
    status: {
        default: "ready", type: yup.string().oneOf(["ready", "active", "locked", "closed"]).required()
    },
    plan: {
        default: "trial", type: yup.string().oneOf(["trial", "normal"]).required()
    },
    shop_id: {
        default: "", type: yup.string().matches(/^[a-z0-9-]{3,30}$/, "利用できない文字が含まれています").max(30).required()
    },
    shop_url: {
        default: "", type: yup.string().url().required(),
    },
    official_url: {
        default: "", type: yup.string().url().optional(),
    },
    name: {
        default: "", type: yup.string().max(30).required()
    },
    email: {
        default: "", type: yup.string().email().required(),
    },
    zip7: {
        default: "", type: yup.string().min(7).max(7).matches(/^[0-9]{7}$/, "利用できない文字が使用されています").required(),
    },
    prefecture: {
        default: "00", type: yup.string().matches(/^[0-9]{2}$/, "不正な都道府県コードです").required(),
    },
    address1: {
        default: "", type: yup.string().max(100).required(),
    },
    address2: {
        default: "", type: yup.string().max(100),
    },
    phone: {
        default: "", type: yup.string().max(15).matches(/^[0-9-]{0,15}$/, "利用できない文字が使用されています").required(),
    },
    fax: {
        default: "", type: yup.string().max(15).matches(/^[0-9-]{0,15}$/, "利用できない文字が使用されています"),
    },
})

export const ShopDownload = new FormObject({
    filetype: {
        default: "csv", type: yup.string().oneOf(["csv"]).required()
    },
})

