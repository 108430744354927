<template>

<div class="error-container">
    <h2>404 Not Found</h2> 
    <div class="section">
    <p>ページが見つかりませんでした。</p>
    </div> 
</div> 

</template>
