<template>
<div>
<template v-if="isLoggedIn">
<div class="mobile-logo"><img src="@/assets/images/logo_bk.png" alt="MOBILE ORDER | 運営管理" /></div>
<div class="global-menu">
    <input type="checkbox" id="global-menu-check"/>
    <label for="global-menu-check" id="global-menu-toggle">
        <span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    </label>
    <nav>
        <h1><img src="@/assets/images/logo.png" alt="運営管理" /></h1>
        <div class="sub-title">運営管理</div>
        <ul class="menu">
            <li class="menu-item"><router-link to="/"><i class="bi-house"></i> ホーム</router-link></li>
            <li class="menu-item"><router-link to="/adminuser/list"><i class="bi-person-fill"></i> 運営ユーザ</router-link></li>
            <li class="menu-item"><router-link to="/shop/list"><i class="bi-shop"></i> ショップ一覧</router-link></li>
            <!-- ショップ管理 -->
            <li class="menu-item">
                <input type="checkbox" id="nav-05" class="menu-accordion-toggle" />
                <label class="menu-accordion-label" for="nav-05"><i class="bi-gear-fill"></i> 管理</label>
                <div class="menu-accordion-content">
                    <ul class="menu">
                        <li class="menu-item"><router-link to="/shop/download">CSVダウンロード</router-link></li>
                    </ul>
                </div>
            </li>
        </ul>
    </nav>
    <div id="menu-overlay"></div>
</div>
</template>

<div id="main-container" :class="{ 'login': ! isLoggedIn }">

<template v-if="isLoggedIn">
<div class="header-meta">
    <div class="login-account dropdown-item">
    <input class="dropdown-toggle" type="checkbox" id="login-menu"/>
    <label for="login-menu">
        <i class="bi-person-circle"></i><span class="login-email">{{ currentUser }}</span>
    </label>
    <div class="content">
        <ul class="dropdown-list">
        <li><router-link to="/mypage"><i class="bi-person-fill"></i> マイページ</router-link></li>
        <li><router-link to="/mypage/change-password"><i class="bi-key-fill"></i> パスワード変更</router-link></li>
        <li><a href="javascript:void(0)" v-on:click="logout()"><i class="bi-box-arrow-right"></i> ログアウト</a></li>
        </ul>
    </div>
    </div>
</div>
<div class="breadcrumbs-container">
    <ul class="breadcrumbs">
    <li><router-link to="/" class="breadcrumbs-home"><i class="bi-house-fill"></i></router-link></li>
    <li v-for="(crumb, index) in breadcrumbs" :key="index">
        <router-link :to="`${crumb.url}`">{{ crumb.title }}</router-link>
    </li>
    <li><span class="current">{{ pageTitle }}</span></li>
    </ul>
</div>
</template>

<div class="main-section">

<router-view></router-view>

</div>

</div><!-- /main-container -->

</div>
</template>
<script setup>

import Axios from "axios"
import { onMounted, ref, shallowRef, reactive, computed, defineAsyncComponent } from 'vue'

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();

const pageTitle = computed(() => store.state.pageTitle);
const breadcrumbs = computed(() => store.state.breadcrumbs);
const currentUser = computed(() => store.state.username);
const isLoggedIn = computed(() => store.state.isLogin);

onMounted(() => {
    Axios.get('/is-login').then(response => {
        store.dispatch("login", {
            uid: response.data.uid,
            username: response.data.username,
            sessionToken: response.data.sid
        })
        if (router.currentRoute.value.path === '/login') {
            // ログイン済の場合は/にリダイレクト
            router.push('/')
        }
        const redirect_url = (new URLSearchParams(new URL(window.location).search)).get('redirect')
        if (redirect_url) {
            router.push(redirect_url)
        }
    }).catch(error => {
        router.push('/login')
    })
})

function logout() {
    Axios.post('/logout', {token: store.state.sessionToken}).then(response => {
        window.location.href = window.location.origin + "/login"
    }).catch(error => {
        window.alert("ログアウトに失敗しました。")
    })
}
</script>
<style>

:root{
    --body-textcolor: #333;
    --body-bgcolor: #f5f7fa;
    --menu-bgcolor: #2a2a2a;
    --menu-toggle-color: #333;
    --primary-color: #333;
}

</style>
