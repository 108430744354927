<template>

    <Form v-slot="{ handleSubmit }" :validation-schema="schema" :initial-values="initialValues" as="div">
    <form @submit="handleSubmit($event, submitForm)">
        <div class="form-group">
            <label for="username">メールアドレス</label>
            <Field type="email" name="username" />
            <ErrorMessage class="form-error-message" as="div" name="username" />
        </div>
        <div class="form-group">
            <label for="password">パスワード</label>
            <Field type="password" name="password" />
            <ErrorMessage class="form-error-message" as="div" name="password" />
        </div>
        <div class="form-group row">
            <div class="offset-sm-3 col-sm-6">
                <div class="checkbox">
                    <label>
                        <Field name="remember" checked="checked" type="checkbox" value="1" /> ログイン状態を保存する
                    </label>
                </div>
            </div>
        </div>
        <div class="form-submit">
            <input type="submit" value="ログイン" :disabled="isSubmitting" />
        </div>
    </form>
    </Form>

</template>
<script setup>

import { ref, defineProps, defineEmits } from '@vue/reactivity'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup';

import { AdminUser } from '../../ptypes.js'

const props = defineProps({
    user: Object,
})
const emits = defineEmits(["submit-form"])

const initialValues = {
    username: props.user.username,
    remember: "1",
};
const schema = yup.object({
    username: AdminUser.username,
    password: AdminUser.password,
    remember: AdminUser.remember,
});
const isSubmitting = ref(false)

const submitForm = (values) => {
    isSubmitting.value = true
    emits("submit-form", values)
    setTimeout(() => {
        isSubmitting.value = false
    }, 1500)
};

</script>
