<template>
    <div class="main-container">
    <div class="login-container">

    <template v-if="message">
    <div class="alert alert-danger" role="alert">{{ message }}</div>
    </template>

    <h2 class="form-title"><i class="bi-box-arrow-in-left" aria-hidden="true"></i> &nbsp;MOBILE ORDER | 運営管理</h2>
    <div class="form">
    <component :is="formComponent" :user="currentUser" @submit-form="login" />
    </div>

    </div>
    </div>
</template>
<script setup>

import Axios from "axios"

import Loading from "./form/Loading.vue"
import FormLogin from "./form/FormLogin.vue"

import { onMounted, ref, shallowRef, reactive, computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
const store = useStore();

const message = ref("")
const currentUser = ref({})

const formComponent = shallowRef(Loading)

new Promise(resolve => {
    setTimeout(()=> {
        resolve()
    }, 800)
}).then(() => {
    currentUser.value = {
        username: ""
    }
    formComponent.value = FormLogin
})

function login(values) {
    Axios.post("/login", {
        email: values.username,
        password: values.password,
        remember: values.remember,
    }).then(response => {
        /*
        store.dispatch("login", {
            username: response.data.username,
            sessionToken: response.data.sid
        })
        */
        // ログイン成功時は一度リロードする
        window.location.href = window.location.origin
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
            message.value = error.response.data.message
        } else {
            message.value = "ログインに失敗しました。"
        }
    })
}

</script>
