
import Axios from 'axios'

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Axios.defaults.withCredentials = true

class ApiRequest {

    constructor(options = {}) {
        this._store = null;
        if (Object.prototype.hasOwnProperty.call(options, "store")) {
            this._store = options.store
        }
        this.apiInstance = Axios.create()
        this.apiInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    window.location.href = window.location.origin + '/admin'
                }
                return Promise.reject(error);
            }
        )
    }

    get(url, config = {}) {
        return this.apiInstance.get(url, config)
    }

    post(url, data, config = {}) {
        if (this._store && this._store.state.sessionToken) {
            data["token"] = this._store.state.sessionToken
        }
        return this.apiInstance.post(url, data, config)
    }
    postMultipartForm(url, data, config = {}) {
        if (this._store && this._store.state.sessionToken) {
            data.append("token", this._store.state.sessionToken)
        }
        if (Object.prototype.hasOwnProperty.call(config, "headers")) {
            config["headers"]["Content-Type"] = 'multipart/form-data'
        } else {
            config["headers"] = {
                'Content-Type': 'multipart/form-data',
            }
        }
        return this.apiInstance.post(url, data, config)
    }

    async searchZip7(zip7) {
        if (zip7.match(/^[0-9]{3}-?[0-9]{4}$/)) {
            zip7 = zip7.replace(/-/, "");
        }
        const res = await Axios.get('https://utils.reiw.net/yubin/zip.php?zip=' + zip7,  {withCredentials: false})
        const addr = {}
        addr.prefecture = res.data.pref
        addr.city = res.data.city
        addr.area = res.data.area
        return addr
    }
}

export default {
    install: (app, options = {}) => {
        const apiRequest = new ApiRequest(options)
        app.provide('ApiRequest', apiRequest)
    }
}
