
import { createApp, defineAsyncComponent  } from 'vue'
import { createStore } from 'vuex'
import { createRouter, createWebHistory } from 'vue-router'

import ApiRequest from "./api-request.js"

import App from "./App.vue"
import Home from "./pages/Home.vue"
import Login from "./pages/Login.vue"
import NotFound from "./pages/NotFound.vue"
import './assets/css/bootstrap-icons.css';
import './assets/css/common.css';
import './assets/css/site.css';

import { setLocale } from "yup";
import { suggestive } from "yup-locale-ja";
setLocale(suggestive)

const store = createStore({
    state: {
        // Login
        isLogin: false,
        uid: null,
        username: '',
        sessionToken: '',

        // Breadcrumbs
        pageTitle: '',
        breadcrumbs: [],
    },
    mutations: {
        // Login
        login(state, userinfo) {
            state.isLogin = true
            state.uid = userinfo.uid
            state.username = userinfo.username
            state.sessionToken = userinfo.sessionToken
        },
        logout(state) {
            state.isLogin = false
            state.uid = null
            state.username = ''
            state.sessionToken = ''
        },

        // Breadcrumbs
        updatePageTitle(state, title) {
            state.pageTitle = title
        },
        updateBreadcrumbs(state, routes) {
            state.breadcrumbs = routes
        },
    },
    actions: {
        // Login
        login(context, userinfo) {
            context.commit('login', userinfo)
        },
        logout(context) {
            context.commit('logout')
        },

        // Breadcrumbs
        updatePageTitle(context, title) {
            context.commit('updatePageTitle', title)
        },
        updateBreadcrumbs(context, routes) {
            context.commit('updateBreadcrumbs', routes)
        },
    },
    modules: {
    }
})

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            requiresAuth: true,
            title: "ホーム",
            breadcrumbs: [],
        }
    },
    {
        path: '/login',
        component: Login,
        meta: {
            requiresAuth: false,
            title: "Login",
            breadcrumbs: [],
        }
    },
    {
        path: '/mypage',
        component: () => import('./pages/MyPageEdit.vue'),
        meta: {
            requiresAuth: true,
            title: "マイページ",
        }
    },
    {
        path: '/mypage/change-password',
        component: () => import('./pages/MyPageChangePassword.vue'),
        meta: {
            requiresAuth: true,
            title: "パスワードの変更",
        }
    },
    {
        path: '/adminuser/list',
        component: () => import('./pages/AdminUserList.vue'),
        meta: {
            requiresAuth: true,
            title: "運営ユーザ一覧",
        }
    },
    {
        path: '/adminuser/new',
        component: () => import('./pages/AdminUserNew.vue'),
        meta: {
            requiresAuth: true,
            title: "新規登録",
            breadcrumbs: [
                {url: "/adminuser/list", title: "運営ユーザ"},
            ],
        }
    },
    {
        path: '/adminuser/edit/:uid',
        component: () => import('./pages/AdminUserEdit.vue'),
        props: routes => ({
            uid: parseInt(routes.params.uid)
        }),
        meta: {
            requiresAuth: true,
            title: "編集",
            breadcrumbs: [
                {url: "/adminuser/list", title: "運営ユーザ"},
            ],
        }
    },
    {
        path: '/shop/list',
        component: () => import('./pages/ShopList.vue'),
        meta: {
            requiresAuth: true,
            title: "ショップ一覧",
        }
    },
    {
        path: '/shop/new',
        component: () => import('./pages/ShopNew.vue'),
        meta: {
            requiresAuth: true,
            title: "新規登録",
            breadcrumbs: [
                {url: "/shop/list", title: "ショップ一覧"},
            ],
        }
    },
    {
        path: '/shop/edit/:id',
        component: () => import('./pages/ShopEdit.vue'),
        props: routes => ({
            entryId: parseInt(routes.params.id)
        }),
        meta: {
            requiresAuth: true,
            title: "編集",
            breadcrumbs: [
                {url: "/shop/list", title: "ショップ一覧"},
            ],
        }
    },
    {
        path: '/shop/download',
        component: () => import('./pages/ShopDownload.vue'),
        meta: {
            requiresAuth: true,
            title: "ダウンロード",
            breadcrumbs: [
                {url: "/shop/list", title: "ショップ一覧"},
            ],
        }
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
        meta: {
            requiresAuth: false,
            title: "404 Not Found",
            breadcrumbs: [],
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});
router.onError(error => {
    if (error.name === 'ChunkLoadError') {
        window.location.reload()
    }
});
router.beforeEach((to, from, next) => {
    if (store.state.isLogin && to.path === '/login') {
        // ログイン済で/loginにアクセスした場合は/にリダイレクト
        next({
            path: '/',
        })
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 認証が必要なページにアクセスした場合、ログイン中でなければ/loginにリダイレクト
        if (! store.state.isLogin) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next();
        }
    } else {
        next();
    }
});
router.afterEach(to => {
    if (to.meta.title) {
        document.title = to.meta.title + " | MOBILE ORDER 運営管理";
        store.dispatch("updatePageTitle", to.meta.title)
    } else {
        document.title = "MOBILE ORDER 運営管理";
        store.dispatch("updatePageTitle", "")
    }
    if (to.meta.breadcrumbs) {
        store.dispatch("updateBreadcrumbs", to.meta.breadcrumbs)
    } else {
        store.dispatch("updateBreadcrumbs", [])
    }
});

// Vue3
const app = createApp(App)
app.use(router)
app.use(store)
app.use(ApiRequest, {store: store})

app.config.globalProperties.$weekday_names = {'mon': '月', 'tue': '火', 'wed': '水', 'thu': '木', 'fri': '金', 'sat': '土', 'sun': '日', 'shuku': '祝日'}

app.config.globalProperties.$plan_names = {'trial': '体験中', 'normal': '通常プラン'}

app.config.globalProperties.$prefecture_map = {
    "01": "北海道", "02": "青森県", "03": "岩手県", "04": "宮城県", "05": "秋田県",
    "06": "山形県", "07": "福島県", "08": "茨城県", "09": "栃木県", "10": "群馬県",
    "11": "埼玉県", "12": "千葉県", "13": "東京都", "14": "神奈川県", "15": "新潟県",
    "16": "富山県", "17": "石川県", "18": "福井県", "19": "山梨県", "20": "長野県",
    "21": "岐阜県", "22": "静岡県", "23": "愛知県", "24": "三重県", "25": "滋賀県",
    "26": "京都府", "27": "大阪府", "28": "兵庫県", "29": "奈良県", "30": "和歌山県",
    "31": "鳥取県", "32": "島根県", "33": "岡山県", "34": "広島県", "35": "山口県",
    "36": "徳島県", "37": "香川県", "38": "愛媛県", "39": "高知県", "40": "福岡県",
    "41": "佐賀県", "42": "長崎県", "43": "熊本県", "44": "大分県", "45": "宮崎県",
    "46": "鹿児島県", "47": "沖縄県",
    "00": "その他"
}

app.mount('#app');
